/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('bootstrap-select');
require('jquery-validation');
require('../theme/common_scripts');
require('../theme/functions');
require('../theme/tabs');